(function(app) {
    'use strict';

    app.component('frtActivityResult', {
        templateUrl: '/js/components/frtActivityResult/activity-result.html',
        bindings: {
            result: '<',
            activity: '<',
            point: '<',
            task: '<',
            activityType: '@'
        },
        controllerAs: 'vm',
        controller: function($rootScope, CONFIG, _apiService, _i18n, _view, Notification, gettext, gettextCatalog, $timeout, _taskService) {
            var vm = this,
                NOTSET = gettextCatalog.getString('Not set'),
                NOTAVAILABLE = gettextCatalog.getString('n/a'),
                NOINSECT = gettextCatalog.getString('No pests'),
                NODISEASE = gettextCatalog.getString('No diseases'),
                NOWEED = gettextCatalog.getString('No weeds'),
                activity = vm.activity.type.rawName,
                NOID = -1,
                NOCOUNT = 0;

            var currentField = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField() : _view.getPanelField();

            vm.edit = false;

            vm.is = {
                activity: {
                    insect: activity === 'INSECTS',
                    disease: activity === 'DISEASES',
                    fenology: activity === 'FENOLOGY',
                    stand: activity === 'STAND',
                    weed: activity === 'WEEDS',
                    note: activity === 'NOTE',
                    pa_insect: activity === 'PA_INSECT',
                    pa_disease: activity === 'PA_DISEASE',
                    //regular_note: activity === 'REGULAR_NOTE'
                },
                crop: {
                    soybean: false,
                    corn: false,
                    cotton: false,
                    winterWheat: false
                }
            };

            vm.isPAactivity = vm.is.activity.pa_insect || vm.is.activity.pa_disease;

            if (vm.isPAactivity) {
                _.each(vm.result.mediaList, function (m) {
                    m.orderedActivityProps = establishOrder(JSON.parse(m.resultJson));
                });
            }

            vm.slider = {};

            vm.orderedActivityProps = establishOrder(vm.result.resultJson);

            /**
             * Get properties labels
             * @param  {string} key JSON property key
             * @return {string}     Return divided key
             */
            vm.getLabelOf = function(key) {
                return _i18n.getString('task.activityOptions.' + key);
            };

            function establishOrder(json) {
                var excludedFields = ['sowingId','samplingObject'];
                var f = { //field sorting on view
                    fenology: ['principalStage', 'cropStage', 'plantStage', 'plantHeight', 'nodesCount', 'nodulationQuality', 'silksCount', 'stemsCount', 'rootLength', 'leavesCount',
                        'kernelsCount', 'flowersCount', 'beansCount', 'squaresCount', 'smallBollsCount', 'mediumBollsCount',
                        'bigBollsCount', 'tassel', 'tillersCount', 'earsCount', 'grainsPerEar'
                    ],
                    insect: ['insect', 'stage', 'count'],
                    pa_insect: ['insect', 'stage', 'selectedEvaluationScaleItem', 'count'],
                    weed: ['weed', 'stage', 'count'],
                    stand: ['principalStage', 'cropStage', 'count', 'selectedEvaluationScaleItem'] ,
                    disease: ['disease', 'severity'],
                    pa_disease: ['disease','selectedEvaluationScaleItem', 'severity', 'count']
                };

                var deny = {
                    pa_insect: ['insect', 'stage'],
                    insect: ['insect', 'stage'],
                    pa_disease: ['disease'],
                    disease: ['disease'],
                    weed: ['weed', 'stage']
                };

                var result = [];
                var currentActivity = null;

                _.some(vm.is.activity, (value, key) => {
                    if (value) {
                        currentActivity = key;
                        return true;
                    }
                });

                if (currentActivity != null) {
                    var theFilter = f[currentActivity];

                    _.each(theFilter, propName => {
                        _.each(json, (prop, name) => {
                            if (propName == name && (vm.activityType != 'cropmonitor' || !_.contains(deny[currentActivity], name))) {
                                result.push({name: name, value: toView(json, name)});
                            }
                        });
                    });

                    //push properties not present in filter at the end of result
                    _.each(json, (prop, name) => {
                        if (!_.contains(theFilter, name) && !_.contains(excludedFields, name) && (vm.activityType != 'cropmonitor' || !_.contains(deny[currentActivity], name))) {
                            result.push({ name: name, value: toView(json, name) });
                        }
                    });
                }

                return result;
            }

            function toView(activity, property) {  
                var value = activity[property];

                if (!_.isUndefined(value)) {
                    if (_.isObject(value)) {

                        if (_.contains(["insect", "weed", "disease"], property)) {
                            var count = activity.count;
                            if (property == "disease") {
                                count = activity.severity;
                            }
                            if (count > 0 || vm.is.activity.pa_insect || vm.is.activity.pa_disease) {
                                return _i18n.getStringFromBundleLat(value.name);
                            } else {
                                if (count == 0) { //no value
                                    if (property == 'insect') {
                                        return NOINSECT;
                                    } else if (property == 'disease') {
                                        return NODISEASE;
                                    } else if (property == 'weed') {
                                        return NOWEED;
                                    }
                                } else { //skip classification, count = -1
                                    return NOTSET;
                                }
                            }
                        } else {
                            //skip stand {"principalStage":{"name":""},"cropStage":{"id":-1,"name":""},"count":-1}
                            //skip fenology {"cropStage":{"id":-1,"name":""},"principalStage":{"name":""}}
                            if (vm.is.activity.stand || vm.is.activity.fenology) {
                                if (activity.cropStage.id != -1) {
                                    return value.name;
                                } else {
                                    return NOTSET;
                                }
                            } else {
                                if (value.id != -1) {
                                    return _i18n.getStringFromBundleEn(value.name);
                                } else {
                                    return NOTAVAILABLE;
                                }
                            }
                        }
                    } else {
                        if (property == "nodulationQuality") {
                            if (value == 1) {
                                return _i18n.getString("nodulationQuality.good");
                            }
                            if (value == 2) {
                                return _i18n.getString("nodulationQuality.medium");
                            }
                            if (value == 3) {
                                return _i18n.getString("nodulationQuality.bad");
                            }
                        }
                        return (_.isNull(value) || _.isUndefined(value) || (value === -1)) ? NOTAVAILABLE : value.toString();
                    }
                } 

                return "";
            }  

            vm.editResult = function(original) {
                vm.isLoading = true;

                // The copy of the resultJson object
                vm.copy = JSON.parse(JSON.stringify(original));

                console.log("edit", JSON.stringify(_.clone(vm.copy)));

                vm.slider.options = {
                    floor: 0,
                    step: 1,
                    disabled: false,
                    hideLimitLabels: true,
                    hidePointerLabels: true
                };

                if (vm.is.activity.insect) {
                    initInsect();
                }
                if (vm.is.activity.weed) {
                    initWeed();
                }
                if (vm.is.activity.note) {
                    vm.edit = true;
                    vm.isLoading = false;
                }
                if (vm.is.activity.regular_note) {
                    vm.edit = true;
                    vm.isLoading = false;
                }
                if (vm.is.activity.disease) {
                    initDisease();
                }
                if (vm.is.activity.stand) {
                    initStand();
                }
                if (vm.is.activity.fenology) {
                    initFenology();
                }
            };

            //copy properties present in original object only
            function copyByTemplate(template, source) {
                var target = {};
                if (!_.isUndefined(source)) {
                    if (_.isArray(template)) {
                        target = source; //todo arrays are copied without template logic
                    } else if (_.isObject(template)) {
                        _.each(_.keys(template), function (key) {
                            if (_.isObject(template[key])) {
                                target[key] = copyByTemplate(template[key], source[key]);
                            } else {
                                if (_.isUndefined(source[key])) {
                                    target[key] = null; //set null to include property in json export
                                } else {
                                    target[key] = source[key];
                                }
                            }
                        });
                    }
                }

                return target;
            }

            vm.updateResult = function() {
                vm.isLoading = true;
                if (vm.is.activity.fenology) {
                    //nodulationQuality case
                    if (!_.isUndefined(vm.copy.nodulationQuality) && !_.isUndefined(vm.nodulationQualityModel)) {
                        vm.copy.nodulationQuality = vm.nodulationQualityModel.id;
                    }
                    vm.result.resultJson = copyByTemplate(vm.result.resultJson, vm.copy); //copy only values were in resultJson
                    //replace primitive fields
                    _.each(_.keys(vm.result.resultJson), function(property) {
                        if (!_.isObject(vm.result.resultJson[property])) {
                            delete vm.result.resultJson[property];
                        }
                    });
                    _.each(_.keys(vm.copy), function(property) {
                        if (!_.isObject(vm.copy[property])) {
                            vm.result.resultJson[property] = vm.copy[property];
                        }
                    });

                } else {
                    vm.result.resultJson = copyByTemplate(vm.result.resultJson, vm.copy); //copy only values were in resultJson
                }

                console.log("save", JSON.stringify(_.clone(vm.result.resultJson)));

                //vm.isLoading = false;
                //vm.edit = false;
                //return;

                if (vm.activityType == 'cropmonitor') {
                    _apiService.updatePointResult(_view.getCurrentAccount(), vm.point.id, vm.result.id, vm.result).then(function() {

                        vm.orderedActivityProps = establishOrder(vm.result.resultJson);
                        vm.edit = false;
                        Notification.success(gettextCatalog.getString('Results were updated'));

                        _.each(vm.point.results, function (r) {
                            if (r.id == vm.result.id) {
                                r.resultJson = vm.result.resultJson;
                            }
                        });
                        _taskService.groupResults(vm.task);

                        vm.isLoading = false;
                    }, err => {
                        vm.isLoading = false;
                        Notification.error(gettextCatalog.getString('Results weren\'t updated'));
                    });
                } else {
                    _apiService.updatePhotoNoteResult(_view.getCurrentAccount(), vm.result.id, vm.result).then(function() {
                        vm.isLoading = false;
                        vm.orderedActivityProps = establishOrder(vm.result.resultJson);
                        vm.edit = false;
                        Notification.success(gettextCatalog.getString('Results were updated'));
                    }, err => {
                        vm.isLoading = false;
                        Notification.error(gettextCatalog.getString('Results weren\'t updated'));
                    });
                }
            };

            vm.cancelChanges = function() {
                vm.edit = false;
            };

            vm.setTassel = function(value) {
                vm.copy.tassel = vm.settings.tassel = value;
            };


            //no insect
            //{"insect":{"id":-1,"name":"Нет вредителей"},"stage":{"id":-1,"name":"","stageOrder":-1},"count":0}

            //skip classification
            //{"insect":{"id":-1,"name":""},"stage":{"id":-1,"name":"","stageOrder":-1},"count":-1}
            function initInsect() {
                _apiService.getInsects(_view.getCurrentAccount()).then(function(res) {
                    vm.insects = [{ id: NOID, formName: NOINSECT, name:"en:No pests,ru:Нет вредителей,pt:Sem pragas" }];
                    vm.stages = [];
                    vm.insectSelectedBean = { id: vm.insects[0].id }; //select No insects by default
                    vm.stageSelectedBean = { id: NOID };
                    vm.stageSelectedDisabled = true;

                    if (vm.copy.insect.id < 1) {
                        vm.slider.options.disabled = true;
                        vm.copy.count = NOCOUNT;
                    }

                    _.each(res.data, function(insect) {
                        var i = insect;
                        i.formName = _i18n.getStringFromBundleLat(i.name);
                        _.each(i.stages, function(s) {
                            s.formName = _i18n.getStringFromBundleEn(s.type.name);
                        });

                        if (vm.copy.insect.id == insect.id) {
                            vm.insectSelectedBean = { id: i.id };
                            vm.stageSelectedBean = { id: vm.copy.stage.id };
                            vm.stages = i.stages;
                            vm.stageSelectedDisabled = false;
                            vm.slider.options.ceil = i.maxDetected;
                        }
                        vm.insects.push(i);
                    });

                    vm.insectSelectCallback = function(i) {
                        vm.copy.insect = i;
                        if (i.id == NOID) { //no insects selected
                            vm.stageSelectedDisabled = true;
                            vm.slider.options.disabled = true;
                            vm.copy.count = NOCOUNT;
                            vm.copy.stage = { id: NOID, name: "" };
                        } else {
                            vm.stageSelectedDisabled = false;
                            vm.slider.options.disabled = false;
                            vm.stages = i.stages;
                            vm.slider.options.ceil = i.maxDetected;
                            $timeout(function() {
                                vm.stageSelectedBean.id = vm.stages[0].id;
                                vm.copy.stage = vm.stages[0];
                            }, 0);
                        }
                    };

                    vm.stageSelectCallback = function(i) {
                        vm.copy.stage = i;
                    };

                    vm.edit = true;
                    vm.isLoading = false;
                }, function() {
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                    vm.isLoading = false;
                });
            }

            function initWeed() {
                _apiService.getWeeds(_view.getCurrentAccount()).then(function(res) {
                    vm.weeds = [{ id: NOID, formName: NOWEED, name: "en:No weeds,ru:Нет сорняков,pt:Sem daninhas" }];
                    vm.stages = [];
                    vm.weedSelectedBean = { id: vm.weeds[0].id }; //select No weeds by default
                    vm.stageSelectedBean = { id: NOID };
                    vm.stageSelectedDisabled = true;

                    if (vm.copy.weed.id < 1) {
                        vm.slider.options.disabled = true;
                        vm.copy.count = NOCOUNT;
                    }

                    _.each(res.data, function(weed) {
                        var i = weed;
                        i.formName = _i18n.getStringFromBundleLat(i.name);
                        _.each(i.stages, function(s) {
                            s.formName = _i18n.getStringFromBundleEn(s.type.name);
                        });

                        if (vm.copy.weed.id == weed.id) {
                            vm.weedSelectedBean = { id: i.id };
                            vm.stageSelectedBean = { id: vm.copy.stage.id };
                            vm.stages = i.stages;
                            vm.stageSelectedDisabled = false;
                            vm.slider.options.ceil = i.maxDetected;
                        }
                        vm.weeds.push(i);
                    });

                    vm.weedSelectCallback = function(i) {
                        vm.copy.weed = i;
                        if (i.id == NOID) { //no insects selected
                            vm.stageSelectedDisabled = true;
                            vm.slider.options.disabled = true;
                            vm.copy.count = NOCOUNT;
                            vm.copy.stage = { id: NOID, name: "" };
                        } else {
                            vm.stageSelectedDisabled = false;
                            vm.slider.options.disabled = false;
                            vm.stages = i.stages;
                            vm.slider.options.ceil = i.maxDetected;
                            $timeout(function() {
                                vm.stageSelectedBean.id = vm.stages[0].id;
                                vm.copy.stage = vm.stages[0];
                            }, 0);
                        }
                    };

                    vm.stageSelectCallback = function(i) {
                        vm.copy.stage = i;
                    };

                    vm.edit = true;
                    vm.isLoading = false;
                }, function() {
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                    vm.isLoading = false;
                });
            }

            function initDisease() {
                _apiService.fieldCrops(_view.getCurrentAccount(), currentField.id).then(resz => {
                    var currentCrop = findCurrentCrop(resz.data);

                    _apiService.getDiseases(_view.getCurrentAccount()).then(function(res) {
                        vm.diseases = [{ id: NOID, formName: NODISEASE, name:"en:No diseases,ru:Нет болезней,pt:Sem doenças"}];
                        vm.diseaseSelectedBean = { id: vm.diseases[0].id }; //select No diseases by default

                        if (vm.copy.disease.id < 1) {
                            vm.slider.options.disabled = true;
                            vm.copy.severity = NOCOUNT;
                        }

                        _.each(res.data, function(disease) {
                            var isAvailableDisease = false;

                            if (currentCrop) {
                                isAvailableDisease = _.find(disease.crops, crop => {
                                    return crop.id == currentCrop.variety.crop.id; });
                            }

                            if (isAvailableDisease) {
                                var i = disease;
                                i.formName = _i18n.getStringFromBundleLat(i.name);


                                if (vm.copy.disease.id == disease.id) {
                                    vm.diseaseSelectedBean = { id: i.id };
                                    vm.slider.options.ceil = i.maxDetected;
                                }
                                vm.diseases.push(i);
                            }
                        });

                        vm.diseaseSelectCallback = function(i) {
                            vm.copy.disease = i;
                            if (i.id == NOID) { //no disease selected
                                vm.slider.options.disabled = true;
                                vm.copy.severity = NOCOUNT;
                            } else {
                                vm.slider.options.disabled = false;
                                vm.slider.options.ceil = i.maxDetected;
                            }
                        };

                        vm.edit = true;
                        vm.isLoading = false;
                    }, function() {
                        Notification.error(_i18n.getString("common.notifications.apiError"));
                        vm.isLoading = false;
                    });
                }, function() {
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                    vm.isLoading = false;
                });
            }

            function initFenology() {
                var availableControls = {
                    corn: {
                        silksCount: ['V10', 'V11', 'V12', 'V13', 'V14', 'V15', 'V16', 'V17', 'V18', 'V19', 'V20', 'VT', 'R1', 'R2', 'R3'],
                        rootLength: ['V0', 'VE', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7'],
                        leavesCount: ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10', 'V11', 'V12', 'V13', 'V14', 'V15', 'V16', 'V17', 'V18', 'V19', 'V20'],
                        kernelsCount: ['VT', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6'],
                        earsCount: ['VT', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6'],
                        tassel: ['V10', 'V11', 'V12', 'V13', 'V14', 'V15', 'V16', 'V17', 'V18', 'V19', 'V20', 'VT', 'R1', 'R2']
                    },
                    soybean: {
                        flowersCount: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6'],
                        rootLength: ['VE', 'VC', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'R1', 'R2', 'R3'],
                        leavesCount: ['VC', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7'],
                        beansCount: ['R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8'],
                        nodesCount: ['VE', 'VC', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8'],
                        plantHeight: ['VE', 'VC', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8'],
                        nodulationQuality: ['VE', 'VC', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'R1']
                    },
                    cotton: {
                        leavesCount: ['V1', 'V2', 'V3', 'V4', 'V5', 'VN', 'B1', 'B2', 'B3', 'B4', 'BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN'],
                        rootLength: ['VE', 'V1', 'V2', 'V3', 'V4', 'V5', 'VN', 'B1', 'B2', 'B3', 'B4', 'BN', 'F1'],
                        flowersCount: ['B1', 'B2', 'B3', 'B4', 'BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        squaresCount: ['VN', 'B1', 'B2', 'B3', 'B4', 'BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        smallBollsCount: ['BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        mediumBollsCount: ['BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        bigBollsCount: ['BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        nodesCount: ['VE', 'V1', 'V2', 'V3', 'V4', 'V5', 'VN', 'B1', 'B2', 'B3', 'B4', 'BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN'],
                        plantHeight: ['VE', 'V1', 'V2', 'V3', 'V4', 'V5', 'VN', 'B1', 'B2', 'B3', 'B4', 'BN', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'FN', 'C1', 'C2', 'C3', 'C4', 'C5', 'CN']
                    },
                    winterWheat: {
                        leavesCount: ['GS10', 'GS11', 'GS13', 'GS15', 'GS19', 'GS20', 'GS21', 'GS23', 'GS25', 'GS29'],
                        stemsCount: ['GS30', 'GS31', 'GS32', 'GS33', 'GS37', 'GS39', 'GS41', 'GS43', 'GS45', 'GS47', 'GS51', 'GS55', 'GS59', 'GS61', 'GS65', 'GS69'],
                        rootLength: ['GS10', 'GS11', 'GS13', 'GS15', 'GS19', 'GS20', 'GS21', 'GS23', 'GS25', 'GS29', 'GS30', 'GS31', 'GS32', 'GS33', 'GS37', 'GS39', 'GS41', 'GS43', 'GS45', 'GS47'],
                        tillersCount: ['GS10', 'GS11', 'GS13', 'GS15', 'GS19', 'GS20', 'GS21', 'GS23', 'GS25', 'GS29'],
                        earsCount: ['GS41', 'GS43', 'GS45', 'GS47', 'GS51', 'GS55', 'GS59', 'GS61', 'GS65', 'GS69', 'GS71', 'GS73', 'GS75', 'GS77', 'GS83', 'GS85', 'GS87', 'GS91', 'GS92', 'GS93', 'GS94'],
                        grainsPerEar: ['GS71', 'GS73', 'GS75', 'GS77', 'GS83', 'GS85', 'GS87', 'GS91', 'GS92', 'GS93', 'GS94']
                    }
                };

                //slider controls max values
                var ranges = {
                    corn: {
                        silksCount: 80,
                        rootLength: 70,
                        leavesCount: 50,
                        kernelsCount: 10,
                        tassel: 1,
                        earsCount: 10
                    },
                    soybean: {
                        flowersCount: 100,
                        rootLength: 70,
                        leavesCount: 100,
                        beansCount: 200,
                        nodesCount: 30,
                        plantHeight: 150
                        //nodulationQualityModel not used
                    },
                    cotton: {
                        leavesCount: 100,
                        rootLength: 100,
                        flowersCount: 100,
                        squaresCount: 100,
                        smallBollsCount: 100,
                        mediumBollsCount: 100,
                        bigBollsCount: 100,
                        nodesCount: 20,
                        plantHeight: 250,
                    },
                    winterWheat: {
                        leavesCount: 20,
                        stemsCount: 40,
                        rootLength: 120,
                        tillersCount: 20,
                        earsCount: 40,
                        grainsPerEar: 200
                    }
                };

                _apiService.fieldCrops(_view.getCurrentAccount(), currentField.id, true, true).then(resz => {
                    var currentCrop = findCurrentCrop(resz.data);
                    if (currentCrop) {
                        vm.is.crop.winterWheat = _i18n.getEnStringFromBundle(currentCrop.variety.crop.name).toLowerCase() == "Winter wheat".toLowerCase();
                        vm.is.crop.soybean = _i18n.getEnStringFromBundle(currentCrop.variety.crop.name).toLowerCase() == "soybeans".toLowerCase();
                        vm.is.crop.corn = _i18n.getEnStringFromBundle(currentCrop.variety.crop.name).toLowerCase() == "corn".toLowerCase();
                        vm.is.crop.cotton = _i18n.getEnStringFromBundle(currentCrop.variety.crop.name).toLowerCase() == "cotton".toLowerCase();

                        var ac = {};
                        if (vm.is.crop.winterWheat) {
                            ac = availableControls.winterWheat;
                        } else if (vm.is.crop.soybean) {
                            ac = availableControls.soybean;
                        } else if (vm.is.crop.corn) {
                            ac = availableControls.corn;
                        } else if (vm.is.crop.cotton) {
                            ac = availableControls.cotton;
                        }


                        _.each(ac, function(stages, name) {
                            vm.slider[name] = { options: _.clone(vm.slider.options) };
                            if (vm.is.crop.corn) {
                                vm.slider[name].options.ceil = ranges.corn[name];
                            } else if (vm.is.crop.soybean) {
                                vm.slider[name].options.ceil = ranges.soybean[name];
                            } else if (vm.is.crop.cotton) {
                                vm.slider[name].options.ceil = ranges.cotton[name];
                            } else if (vm.is.crop.winterWheat) {
                                vm.slider[name].options.ceil = ranges.winterWheat[name];
                            }

                        });

                        vm.nodulationQualityOptions = [{id: 1, name: _i18n.getString("nodulationQuality.good")},
                            {id: 2, name: _i18n.getString("nodulationQuality.medium")},
                            {id: 3, name: _i18n.getString("nodulationQuality.bad")}];
                        vm.nodulationQualityModel = _.find(vm.nodulationQualityOptions, function (e) {
                           return e.id == vm.copy.nodulationQuality;
                        });




                        vm.controls = {};

                        vm.stages = _.map(_.values(currentCrop.stages), function(s) {
                            s.id = s.stageId;
                            s.name = s.stage;
                            s.formName = s.stage;
                            return s;
                        });

                        if (vm.is.crop.soybean || vm.is.crop.corn || vm.is.crop.cotton) {
                            if (vm.copy.cropStage.id < 1) {
                                vm.copy.cropStage = vm.stages[0];
                            } else {
                                if (!_.contains(_.map(vm.stages, function(s) {
                                        return s.id; }), vm.copy.cropStage.id)) {
                                    vm.copy.cropStage = vm.stages[0];
                                }
                            }

                            vm.stageSelectedBean = { id: vm.copy.cropStage.id };

                            refreshFenologyControls(ac, vm.copy.cropStage.name, vm.controls, vm.copy);

                            vm.stageSelectCallback = function(i) {
                                vm.copy.cropStage = i;
                                refreshFenologyControls(ac, vm.copy.cropStage.name, vm.controls, vm.copy);
                            };
                        }

                        if (vm.is.crop.winterWheat) {
                            var data = getWinterWheatStages();

                            vm.principalStages = [];
                            _.each(data, function(ps) {
                                var principalStage = createPrincipalStage(ps[0], ps[1], ps[2], ps[3], vm.stages);
                                if (_.size(principalStage.stages) > 0) {
                                    vm.principalStages.push(principalStage);
                                }
                            });

                            vm.stages = []; //stages will be taken from principalStage.stages
                            vm.stageSelectedBean = { id: vm.copy.cropStage.id, name: vm.copy.cropStage.name };

                            if (_.size(vm.principalStages) > 0) {
                                //todo set default

                                var stagesCorrect = true;

                                if (vm.copy.principalStage.name == "") {
                                    stagesCorrect = false;
                                } else {
                                    var ps = _.find(vm.principalStages, function(ps) {
                                        return ps.name == vm.copy.principalStage.name;
                                    });

                                    if (ps) {
                                        var ss = _.find(ps.stages, function(s) {
                                            return s.name == vm.copy.cropStage.name;
                                        });

                                        if (ss) {
                                            vm.principalStageSelectedBean = { id: ps.id };
                                            vm.stages = ps.stages;
                                            vm.copy.cropStage = ss;
                                            vm.stageSelectedBean.id = vm.copy.cropStage.id;
                                            vm.stageSelectedBean.name = vm.copy.cropStage.name;
                                        } else {
                                            stagesCorrect = false;
                                        }
                                    } else {
                                        stagesCorrect = false;
                                    }
                                }

                                if (!stagesCorrect) {
                                    vm.copy.principalStage = vm.principalStages[0];
                                    vm.principalStageSelectedBean = { id: vm.copy.principalStage.id };

                                    vm.stages = vm.principalStages[0].stages;
                                    vm.copy.cropStage = vm.stages[0];

                                    vm.stageSelectedBean.id = vm.principalStages[0].stages[0].id;
                                    vm.stageSelectedBean.name = vm.principalStages[0].stages[0].name;
                                }

                                refreshFenologyControls(ac, vm.stageSelectedBean.name, vm.controls, vm.copy);

                                vm.principalStageSelectCallback = function(i) {
                                    vm.copy.principalStage = i;
                                    vm.stages = i.stages;

                                    $timeout(function() {
                                        vm.copy.cropStage = vm.stages[0];
                                        vm.stageSelectedBean.id = vm.stages[0].id;
                                        refreshFenologyControls(ac, vm.copy.cropStage.name, vm.controls, vm.copy);
                                    }, 0);
                                }
                            }

                            vm.stageSelectCallback = function(i) {
                                vm.copy.cropStage = i;
                            };
                        }
                    }

                    vm.edit = true;
                    vm.isLoading = false;
                });


            }

            function refreshFenologyControls(ac, value, controls, copy) {
                _.each(ac, function(stages, name) {
                    if (_.contains(stages, value)) {
                        controls[name] = true;
                        if (_.isUndefined(copy[name])) { //add new property in edit bean
                            copy[name] = 0;
                        }
                    } else {
                        controls[name] = false;
                        if (!_.isUndefined(copy[name])) {
                            delete copy[name];
                        }
                    }
                });
            }

            function initStand() {
                _apiService.fieldCrops(_view.getCurrentAccount(), currentField.id, true, true).then(resz => {

                    var currentCrop = findCurrentCrop(resz.data);

                    if (currentCrop) {
                        vm.is.crop.winterWheat = currentCrop.variety.crop.name.toLowerCase() == "Winter wheat".toLowerCase();
                        vm.stages = _.map(_.values(currentCrop.stages), function(s) {
                            s.id = s.stageId;
                            s.name = s.stage;
                            s.formName = s.stage;
                            return s;
                        });

                        if (vm.copy.cropStage.id < 1) {
                            vm.copy.cropStage = vm.stages[0];
                        }

                        vm.stageSelectedBean = { id: vm.copy.cropStage.id };

                        if (vm.copy.count < 0) {
                            vm.copy.count = NOCOUNT;
                        }

                        vm.slider.options.ceil = 125; //hardcoded value for all stages

                        if (vm.is.crop.winterWheat) {
                            vm.slider.options.ceil = 200;
                            //holds stage code and bundle, because now bundle is not returned via api
                            var data = getWinterWheatStages();

                            vm.principalStages = [];
                            _.each(data, function(ps) {
                                var principalStage = createPrincipalStage(ps[0], ps[1], ps[2], ps[3], vm.stages);
                                if (_.size(principalStage.stages) > 0) {
                                    vm.principalStages.push(principalStage);
                                }
                            });

                            vm.stages = []; //stages will be taken from principalStage.stages
                            if (_.size(vm.principalStages) > 0) {
                                if (vm.copy.principalStage.name == "") {
                                    vm.copy.principalStage = vm.principalStages[0];
                                    vm.principalStageSelectedBean = { id: vm.copy.principalStage.id };
                                    vm.stages = vm.principalStages[0].stages;
                                } else {
                                    var ps = _.find(vm.principalStages, function(ps) {
                                        return ps.name == vm.copy.principalStage.name;
                                    });
                                    if (ps) {
                                        vm.principalStageSelectedBean = { id: ps.id };
                                        vm.stages = ps.stages;
                                    } else {
                                        vm.copy.principalStage = vm.principalStages[0];
                                        vm.principalStageSelectedBean = { id: vm.copy.principalStage.id };
                                        vm.stages = vm.principalStages[0].stages;
                                        vm.stageSelectedBean.id = vm.principalStages[0].stages[0].id;
                                    }
                                }

                                vm.principalStageSelectCallback = function(i) {
                                    vm.copy.principalStage = i;
                                    vm.stages = i.stages;
                                    $timeout(function() {
                                        vm.copy.cropStage = vm.stages[0];
                                        vm.stageSelectedBean.id = vm.stages[0].id;
                                    }, 0);
                                }
                            }
                        }

                        vm.stageSelectCallback = function(i) {
                            vm.copy.cropStage = i;
                        };
                    }

                    vm.edit = true;
                    vm.isLoading = false;

                }, function() {
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                    vm.isLoading = false;
                });
            }

            function createPrincipalStage(id, code, bundle, stages, stagesDict) {
                var result = { id: id, name: code, formName: _i18n.getStringFromBundleEn(bundle), stages: [] };
                _.each(stages, function(s) {
                    var dictStage = _.find(stagesDict, function(sd) {
                        return s[0] == sd.stage; });
                    if (dictStage) {
                        result.stages.push({
                            id: dictStage.id,
                            name: s[0],
                            formName: _i18n.getStringFromBundleEn(s[1])
                        });
                    }
                });

                return result;
            }

            //find current crop for crop monitor or for photo note
            function findCurrentCrop(crops) {
                var currentCrop = null;
                var currentPhotoNode = _view.getViewPhotoNote();

                if (currentPhotoNode) {
                    var sowingId = vm.result.resultJson.sowingId;
                    if (sowingId) {
                        _.each(crops, function(c) { //crops loop
                            if (c.id == sowingId) {
                                currentCrop = c;
                            }
                        });
                    }
                } else {
                    var currentTask = _view.getViewCropMonitor();
                    _.each(crops, function(c) { //crops loop
                        _.each(c.tasks, function(t) { //tasks loop
                            if (t.id == currentTask) {
                                currentCrop = c;
                            }
                        });
                    });
                }

                return currentCrop;
            }

            function getWinterWheatStages() {
                return [
                    [1, "PGS1", "en:PGS 1 Seedling growth,ru:1 Развитие листьев", [
                        ["GS10", "en:GS10 First leaf through coleoptile,ru:GS10 Первый лист из колеоптиля"],
                        ["GS11", "en:GS11 First leaf unfolded,ru:GS11 Первый лист развернут"],
                        ["GS13", "en:GS13 3 leaves unfolded,ru:GS13 Третий лист развернут"],
                        ["GS15", "en:GS15 5 leaves unfolded,ru:GS15 Пятый лист развернут"],
                        ["GS19", "en:GS19 9 or more leaves unfolded,ru:GS19 9 и больше листьев развернуты"]
                    ]],
                    [2, "PGS2", "en:PGS 2 Tillering,ru:2 Кущение", [
                        ["GS20", "en:GS20 Main shoot only,ru:GS20 Начало кущения - побегов нет"],
                        ["GS21", "en:GS21 Main shoot and one tiller,ru:GS21 Начало кущения - первый побег"],
                        ["GS23", "en:GS23 Main shoot and three tillers,ru:GS23 Кущение-третий побег"],
                        ["GS25", "en:GS25 Main shoot and five tillers,ru:GS25 Кущение-пятый побег"],
                        ["GS29", "en:GS29 Main shoot and 9 or more tillers,ru:GS29 Кущение-девять и более побегов"]
                    ]],
                    [3, "PGS3", "en:PGS 3 Stem elongation,ru:3 Выход в трубку", [
                        ["GS30", "en:GS30 Pseudo stem erection,ru:GS30 Начало выхода в трубку"],
                        ["GS31", "en:GS31 First node,ru:GS31 Виден первый узел"],
                        ["GS32", "en:GS32 Second node,ru:GS32 Виден второй узел"],
                        ["GS33", "en:GS33 Third node,ru:GS33 Виден третий узел"],
                        ["GS37", "en:GS37 Flag leaf just visible,ru:GS37 Появление флагового листа"],
                        ["GS39", "en:GS39 Flag leaf fully visible,ru:GS39 Флаговый лист полностью развит"]
                    ]],
                    [4, "PGS4", "en:PGS 4 Booting,ru:4 Набухание соцветий", [
                        ["GS41", "en:GS41 Flag leaf sheath extending,ru:GS41 Удлиняется влагалище флагового листа"],
                        ["GS43", "en:GS43 Flag leaf sheath just visibly swollen,ru:GS43 Набухает влагалище флагового листа"],
                        ["GS45", "en:GS45 Flag leaf swollen,ru:GS45 Влагалище флагового листа набухло"],
                        ["GS47", "en:GS47:Flag leaf sheath opening,ru:GS47 Влагалище флагового листа открывается"]
                    ]],
                    [5, "PGS5", "en:PGS 5 Heading,ru:5 Появление соцветий", [
                        ["GS51", "en:GS51 First spikelet of ear just visible,ru:GS51 Начало колошения"],
                        ["GS55", "en:GS55 Half of ear emerged above flag leaf ligule,ru:GS55 Появление половины колоса"],
                        ["GS59", "en:GS59 Ear completely emerged above flag leaf ligule,ru:GS59 Колос полностью виден"]
                    ]],
                    [6, "PGS6", "en:PGS 6 Flowering,ru:6 Цветение", [
                        ["GS61", "GS61 Start of flowering,ru:GS61 Начало цветения"],
                        ["GS65", "GS65 Flowering half way,ru:GS65 Середина цветения"],
                        ["GS69", "GS69 Flowering complete,ru:GS69 Конец цветения"]
                    ]],
                    [7, "PGS7", "en:PGS 7 Development of fruit,ru:7 Образование зерен", [
                        ["GS71", "GS71 Grain watery ripe,ru:GS71 Водянистое зерно"],
                        ["GS73", "GS73 Early milk,ru:GS73 Ранняя молочная спелость"],
                        ["GS75", "GS75 Medium milk,ru:GS75 Средняя молочная спелость"],
                        ["GS77", "GS77 Late milk,ru:GS77 Поздняя молочная спелость"]
                    ]],
                    [8, "PGS8", "en:PGS 8 Ripening,ru:8 Созревание зерен", [
                        ["GS83", "GS83 Early dough,ru:GS83 Ранняя восковая спелость"],
                        ["GS85", "GS85 Soft dough,ru:GS85 Мягкая восковая спелость"],
                        ["GS87", "GS87 Hard dough,ru:GS87 Твёрдая восковая спелость"]
                    ]],
                    [9, "PGS9", "en:PGS 9 Senescence,ru:9 Отмирание растения", [
                        ["GS91", "GS91 Grain hard,ru:GS91 Ранняя полная спелость"],
                        ["GS92", "GS92 Grain fully ripe,ru:GS92 Полная спелость"],
                        ["GS93", "GS93 Grain loosening in daytime,ru:GS93 Зерно сидит рыхло в колосе"],
                        ["GS94", "GS94 Significant grain loosening,ru:GS94 Выпадение зерна из колоса"]
                    ]]
                ];
            }
        }
    });

})(angular.module('app'));
